export enum RouteName {
  ClosedDeliveryNote = "closedDeliveryNote",
  ClosedOrderIndex = "closed-order-index",
  ConfirmArrival = "confirm-arrival",
  DeliveryConfirm = "delivery-confirm",
  DeliveryNote = "deliveryNote",
  DeliveryNoteIndex = "delivery-note-index",
  DeliverySummary = "delivery-summary",
  Home = "order-index",
  Loading = "loading",
  Login = "login",
  MyOrders = "my-orders",
  OpenOrders = "open-orders",
  OrderIndex = "order-index",
  OrderShow = "order-show",
  ScanQrCode = "scan-qr-code",
  SelectDriverInformation = "select-driver-information",
  SelectOrderDetails = "select-order-details",
  URLLogin = "urllogin",
  WeighingConfirmation = "weighing-confirmation",
}
