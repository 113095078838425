// Register specific components
import {
  PfreundtBadge,
  PfreundtButton,
  PfreundtHeading,
  PfreundtIcon,
  PfreundtInfoList,
  PfreundtInfobox,
  PfreundtLogo,
  PfreundtLogoCard,
  PfreundtLoginFooter,
  PfreundtToast,
  PfreundtSelect,
  PfreundtOption,
} from "@zweitag/pfreundt-design-system/dist/main";

import "@zweitag/design-system/icon-font.css";

import "@zweitag/pfreundt-design-system/src/assets/globals/fonts.css";
import "@zweitag/pfreundt-design-system/variables.css";
import "@zweitag/pfreundt-design-system/tokens_zweitag_design_system/css/core.css";
import "@zweitag/pfreundt-design-system/tokens_zweitag_design_system/css/theme-default.css";
import "@zweitag/pfreundt-design-system/compositions.css";

import "@zweitag/pfreundt-design-system/src/components/Button/Button.css";

// Check if the custom element has already been defined by another component in this central location.
// This takes the responsibility away from each component and makes the order of definitions irrelevant.
// Previously we had an error when importing the Icon component after a component that had already defined it
// because the Icon component does not check if it is already registered.
const defineCustomElementOnce = (
  tagName: string,
  element: CustomElementConstructor,
) => {
  if (!customElements.get(tagName)) {
    customElements.define(tagName, element);
  }
};

export const setupDesignSystem = () => {
  defineCustomElementOnce("pfreundt-badge", PfreundtBadge);
  defineCustomElementOnce("pfreundt-button", PfreundtButton);
  defineCustomElementOnce("pfreundt-heading", PfreundtHeading);
  defineCustomElementOnce("pfreundt-icon", PfreundtIcon);
  defineCustomElementOnce("pfreundt-infobox", PfreundtInfobox);
  defineCustomElementOnce("pfreundt-info-list", PfreundtInfoList);
  defineCustomElementOnce("pfreundt-logo", PfreundtLogo);
  defineCustomElementOnce("pfreundt-logo-card", PfreundtLogoCard);
  defineCustomElementOnce("pfreundt-login-footer", PfreundtLoginFooter);
  defineCustomElementOnce("pfreundt-toast", PfreundtToast);
  defineCustomElementOnce("pfreundt-select", PfreundtSelect);
  defineCustomElementOnce("pfreundt-option", PfreundtOption);
};
