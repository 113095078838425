import { defineStore } from "pinia";
import { CombinedError } from "urql";
import { ref, type Ref } from "vue";
import { buildExtendedOrder } from "@/helpers";
import type { ExtendedOrderItem } from "@/types";
import {
  useGetOrderQuery,
  useDriverArrivesOnSiteMutation,
  useDriverAcceptsDeliveryRequestMutation,
  useDriverFreesOrderMutation,
} from "@/types/graphql";
import { buildError } from "@/helpers/error/error.helper";

interface OrderStore {
  order: ExtendedOrderItem | null;
  fetching: boolean;
  error: Ref<CombinedError | undefined>;
  driverArrivesOnSiteMutation: ReturnType<
    typeof useDriverArrivesOnSiteMutation
  >;
  driverAcceptsDeliveryRequest: ReturnType<
    typeof useDriverAcceptsDeliveryRequestMutation
  >;
  driverFreesOrder: ReturnType<typeof useDriverFreesOrderMutation>;
}

export const useOrderStore = defineStore("order", {
  state: (): OrderStore => ({
    order: null,
    fetching: false,
    error: ref(undefined),
    driverArrivesOnSiteMutation: useDriverArrivesOnSiteMutation(),
    driverAcceptsDeliveryRequest: useDriverAcceptsDeliveryRequestMutation(),
    driverFreesOrder: useDriverFreesOrderMutation(),
  }),
  actions: {
    async fetchOrder(orderId: string, orderItemId: string) {
      this.error = undefined;
      this.fetching = true;
      const { data, error: originalError } = await useGetOrderQuery({
        variables: { id: orderId, withWeighings: true },
      });
      this.fetching = false;
      this.error = originalError.value;

      if (!data.value?.order) {
        if (!this.error) {
          this.error = new CombinedError({ graphQLErrors: ["No Order given"] });
        }
        return;
      }

      const order = buildExtendedOrder(data.value.order, orderItemId);

      if (!order) return;

      this.order = order;
    },
    updateOrderItem(updatedProperties: Partial<ExtendedOrderItem>) {
      if (!this.order) return;

      this.order = {
        ...this.order,
        ...updatedProperties,
      };
    },
    async startLoading(orderId: string, orderItemId: string) {
      if (!this.order) return;

      const variables = {
        orderId,
      };
      this.fetching = true;
      this.driverArrivesOnSiteMutation
        .executeMutation(variables)
        .then((result) => {
          this.fetching = false;
          if (result.data?.driverArrivesOnSite?.order) {
            const order = buildExtendedOrder(
              result.data?.driverArrivesOnSite?.order,
              orderItemId,
            );

            if (!order) return;

            this.order = order;
          }
        });
    },
    async acceptDeliveryRequest(
      orderId: string,
      orderItemId: string,
      vehicleId: string | null,
      driverId: string | null,
    ): Promise<Boolean> {
      if (!this.order) return false;

      const variables = {
        orderId,
        vehicleId,
        driverId,
      };
      const result =
        await this.driverAcceptsDeliveryRequest.executeMutation(variables);

      if (result.data?.driverAcceptsDeliveryRequest?.order) {
        const order = buildExtendedOrder(
          result.data?.driverAcceptsDeliveryRequest?.order,
          orderItemId,
        );

        if (!order) return false;

        this.order = order;
        return true;
      }
      return false;
    },

    async unassignOrder(orderId: string): Promise<Boolean> {
      if (!this.order) return false;

      this.fetching = true;

      const variables = {
        orderId,
      };
      const result = await this.driverFreesOrder.executeMutation(variables);

      this.fetching = false;

      const graphQLErrors = result.data?.driverFreesOrder?.errors;
      this.error = buildError(graphQLErrors, result.error);
      if (this.error) return false;

      this.order = null;
      return true;
    },
  },
  getters: {
    hasWeighings: (state) =>
      !state.order?.weighingIds ? false : state.order?.weighingIds.length > 0,
  },
});
