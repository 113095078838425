export default {
  errors: {
    failedToFetchOrder: "Order data could not be loaded",
  },
  closedOrderShow: {
    heading: "Order Overview",
  },
  closedOrders: {
    heading: "Closed orders",
  },
  confirmArrival: {
    heading: "Confirm arrival",
    description: "Please confirm your arrival at the customer.",
    confirm: "@:views.confirmArrival.heading",
  },
  deliverySummary: {
    heading: "Delivery",
    instruction: "Drive to the customer to unload the delivery.",
    startRoute: "Start Route",
    openDeliveryNote: "Open Delivery Note",
    details: {
      dateTime: "Delivery until {date} {time}",
    },
  },
  loadingStatus: {
    heading: "Loading",
    description:
      "Please do not move your vehicle during loading and stay in the cab for your safety.",
    call: "Call Dispo",
    ignoreOverload: "Ignore Overload",
    confirm: "Confirm Weighing",
  },
  myOrders: {
    heading: "My orders",
    noOrdersHint: "Add new orders from the open orders.",
  },
  openOrders: {
    heading: "Open orders",
  },
  orderDetails: {
    heading: "Order Details",
    start: "Start Order",
    continue: "Continue Order",
    startLoading: "Open Loading",
    nextLoading: "Next Loading",
    started: "@:order.heading started",
    showDeliveryReceipts: "Show delivery receipts",
  },
  selectDeliveryNote: {
    heading: "Select Delivery Note",
    instruction:
      "Please select the correct delivery note to confirm the weighing.",
    confirm: "Confirm Weighing",
  },
  scanQrCode: {
    heading: "Scan QR Code",
    description: "Please scan the QR code at the terminal",
    confirm: "Start loading",
  },
  selectDriverInformation: {
    noLicensePlate: "No license plate",
    noDriverName: "No driver name",
    driverInformation: "Driver information",
    heading: "Please enter the following data",
    account: "Account",
    drivername: "Driver name",
    vehicle: "Vehicle",
    confirmData: "Confirm data",
  },
  selectOrderDetails: {
    heading: "Order Details",
    hint: "Please enter the following data",
    material: "Material",
    targetWeight: "Target Weight",
    startOrder: "Start order",
  },
  weighingConfirmation: {
    heading: "Confirm Weighing",
    confirmMessage: "Weighing confirmed",
  },
};
