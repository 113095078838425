<script setup lang="ts">
import { onBeforeMount, ref } from "vue";
import { storeToRefs } from "pinia";
import { PageLayout } from "@/layouts";
import { TitleHeader, PfrButton, PfrSelect } from "@/components";
import { useI18n } from "vue-i18n";
import { ButtonElement } from "@/types";
import { useUserStore, useVehiclesStore, useDriversStore } from "@/stores";
import { useRouter } from "vue-router";

const router = useRouter();

const { t } = useI18n();

const userStore = useUserStore();
const driversStore = useDriversStore();
const vehiclesStore = useVehiclesStore();
const { fetchDrivers } = driversStore;
const { fetchVehicles } = vehiclesStore;
const { driverOptions } = storeToRefs(driversStore);
const { vehicleOptions } = storeToRefs(vehiclesStore);

const selectedDriverId = ref(userStore.driverId);
const selectedVehicleId = ref(userStore.vehicleId);

onBeforeMount(() => {
  fetchDrivers();
  fetchVehicles();
});

const saveDriverInformation = () => {
  userStore.setDriverId(selectedDriverId.value);
  userStore.setVehicleId(selectedVehicleId.value);
  router.push("/");
};
</script>

<template>
  <PageLayout>
    <template #header>
      <TitleHeader
        :heading="t('views.selectDriverInformation.driverInformation')"
      />
    </template>

    <template #main>
      <pfreundt-heading tag="h2" size="xsmall" weight="bold" balanced>
        {{ t("views.selectDriverInformation.heading") }}
      </pfreundt-heading>

      <PfrSelect
        id="a11y-select-vehicle"
        v-model="selectedVehicleId"
        field-name="vehicleId"
        :label="t('views.selectDriverInformation.vehicle')"
        required
        :options="vehicleOptions"
      />

      <PfrSelect
        id="a11y-select-driver"
        v-model="selectedDriverId"
        field-name="driverId"
        :label="t('views.selectDriverInformation.drivername')"
        :options="driverOptions"
      />
    </template>

    <template #mainFooter>
      <PfrButton
        :is="ButtonElement.Button"
        :full-width="true"
        @click="saveDriverInformation"
      >
        {{ t("views.selectDriverInformation.confirmData") }}
      </PfrButton>
    </template>
  </PageLayout>
</template>
