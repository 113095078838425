import gql from "graphql-tag";
import * as Urql from "@urql/vue";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never;
    };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  ISO8601DateTime: { input: any; output: any };
};

export type Address = {
  __typename?: "Address";
  city: Scalars["String"]["output"];
  country: Scalars["String"]["output"];
  houseNumber?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  street: Scalars["String"]["output"];
  zipCode: Scalars["String"]["output"];
};

export type Belt = {
  __typename?: "Belt";
  dataPoints: Array<BeltDataPoint>;
  id: Scalars["ID"]["output"];
  idWithinScale: Scalars["ID"]["output"];
  latestMeasurement: LatestMeasurement;
  name: Scalars["String"]["output"];
  scale: Scale;
};

export type BeltDataPointsArgs = {
  from: Scalars["ISO8601DateTime"]["input"];
  to: Scalars["ISO8601DateTime"]["input"];
};

export type BeltDataPoint = {
  __typename?: "BeltDataPoint";
  flowRate?: Maybe<Scalars["Float"]["output"]>;
  status: BeltStatus;
  time: Scalars["ISO8601DateTime"]["output"];
};

export enum BeltStatus {
  Empty = "EMPTY",
  None = "NONE",
  NoData = "NO_DATA",
  Running = "RUNNING",
  Stopped = "STOPPED",
}

export type BlanketOrder = {
  __typename?: "BlanketOrder";
  blanketOrderItems: Array<BlanketOrderItem>;
  customer: Customer;
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  number: Scalars["String"]["output"];
};

/** The connection type for BlanketOrder. */
export type BlanketOrderConnection = {
  __typename?: "BlanketOrderConnection";
  /** A list of edges. */
  edges: Array<BlanketOrderEdge>;
  /** A list of nodes. */
  nodes: Array<BlanketOrder>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type BlanketOrderEdge = {
  __typename?: "BlanketOrderEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node?: Maybe<BlanketOrder>;
};

export type BlanketOrderItem = {
  __typename?: "BlanketOrderItem";
  id: Scalars["ID"]["output"];
  material: Material;
  orderedWeight?: Maybe<Scalars["Float"]["output"]>;
  remainingWeight?: Maybe<Scalars["Float"]["output"]>;
  targetWeight?: Maybe<Scalars["Float"]["output"]>;
  unit: WeightUnit;
};

/** Autogenerated return type of CreateOrderFromBlanketOrder. */
export type CreateOrderFromBlanketOrderPayload = {
  __typename?: "CreateOrderFromBlanketOrderPayload";
  errors: Array<Scalars["String"]["output"]>;
  order?: Maybe<Order>;
};

export type Customer = {
  __typename?: "Customer";
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
};

export type DeliveryPlace = {
  __typename?: "DeliveryPlace";
  address?: Maybe<Address>;
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
};

export type Driver = {
  __typename?: "Driver";
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
};

/** Autogenerated return type of DriverAcceptsDeliveryRequest. */
export type DriverAcceptsDeliveryRequestPayload = {
  __typename?: "DriverAcceptsDeliveryRequestPayload";
  errors: Array<Scalars["String"]["output"]>;
  order?: Maybe<Order>;
};

/** Autogenerated return type of DriverArrivesOnSite. */
export type DriverArrivesOnSitePayload = {
  __typename?: "DriverArrivesOnSitePayload";
  errors: Array<Scalars["String"]["output"]>;
  order?: Maybe<Order>;
};

/** The connection type for Driver. */
export type DriverConnection = {
  __typename?: "DriverConnection";
  /** A list of edges. */
  edges: Array<DriverEdge>;
  /** A list of nodes. */
  nodes: Array<Driver>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type DriverEdge = {
  __typename?: "DriverEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node?: Maybe<Driver>;
};

/** Autogenerated return type of DriverFreesOrder. */
export type DriverFreesOrderPayload = {
  __typename?: "DriverFreesOrderPayload";
  errors: Array<Scalars["String"]["output"]>;
  order?: Maybe<Order>;
};

export type LatestMeasurement = {
  __typename?: "LatestMeasurement";
  dailyTotal?: Maybe<Scalars["Float"]["output"]>;
  flowRate?: Maybe<Scalars["Float"]["output"]>;
  status: BeltStatus;
  time?: Maybe<Scalars["ISO8601DateTime"]["output"]>;
};

export type LoadAndGoOrder = {
  __typename?: "LoadAndGoOrder";
  blanketOrder?: Maybe<BlanketOrder>;
  order?: Maybe<Order>;
  orderType: Scalars["String"]["output"];
};

/** The connection type for LoadAndGoOrder. */
export type LoadAndGoOrderConnection = {
  __typename?: "LoadAndGoOrderConnection";
  /** A list of edges. */
  edges: Array<LoadAndGoOrderEdge>;
  /** A list of nodes. */
  nodes: Array<LoadAndGoOrder>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type LoadAndGoOrderEdge = {
  __typename?: "LoadAndGoOrderEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node?: Maybe<LoadAndGoOrder>;
};

export type Location = {
  __typename?: "Location";
  id: Scalars["ID"]["output"];
  latestMeasurement: LatestMeasurement;
  liveDashboardScales: Array<Scale>;
  name: Scalars["String"]["output"];
  plants: Array<Plant>;
};

export type Material = {
  __typename?: "Material";
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  number: Scalars["String"]["output"];
};

export type Mutation = {
  __typename?: "Mutation";
  createOrderFromBlanketOrder: CreateOrderFromBlanketOrderPayload;
  driverAcceptsDeliveryRequest: DriverAcceptsDeliveryRequestPayload;
  driverArrivesOnSite: DriverArrivesOnSitePayload;
  driverFreesOrder: DriverFreesOrderPayload;
  setZero: SetZeroPayload;
  startSendingLiveValues: StartSendingLiveValuesPayload;
};

export type MutationCreateOrderFromBlanketOrderArgs = {
  blanketOrderId: Scalars["ID"]["input"];
  driverId?: InputMaybe<Scalars["ID"]["input"]>;
  materialId: Scalars["ID"]["input"];
  targetWeight: Scalars["Float"]["input"];
  unit: Scalars["String"]["input"];
  vehicleId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type MutationDriverAcceptsDeliveryRequestArgs = {
  driverId?: InputMaybe<Scalars["ID"]["input"]>;
  orderId: Scalars["ID"]["input"];
  vehicleId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type MutationDriverArrivesOnSiteArgs = {
  orderId: Scalars["ID"]["input"];
};

export type MutationDriverFreesOrderArgs = {
  orderId: Scalars["ID"]["input"];
};

export type MutationSetZeroArgs = {
  scaleId: Scalars["ID"]["input"];
};

export type MutationStartSendingLiveValuesArgs = {
  scaleId: Scalars["ID"]["input"];
};

export type Order = {
  __typename?: "Order";
  customer?: Maybe<Customer>;
  deliveryPlace?: Maybe<DeliveryPlace>;
  deliveryState: Scalars["String"]["output"];
  driver?: Maybe<Driver>;
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  number: Scalars["String"]["output"];
  orderItems: Array<OrderItem>;
  state: Scalars["String"]["output"];
  vehicle?: Maybe<Vehicle>;
};

export type OrderOrderItemsArgs = {
  ids?: InputMaybe<Array<Scalars["ID"]["input"]>>;
};

/** The connection type for Order. */
export type OrderConnection = {
  __typename?: "OrderConnection";
  /** A list of edges. */
  edges: Array<OrderEdge>;
  /** A list of nodes. */
  nodes: Array<Order>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type OrderEdge = {
  __typename?: "OrderEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node?: Maybe<Order>;
};

export type OrderItem = {
  __typename?: "OrderItem";
  id: Scalars["ID"]["output"];
  material: Material;
  targetWeight: Scalars["Float"]["output"];
  unit: WeightUnit;
  weighings: Array<Weighing>;
  weightPromised: Scalars["Float"]["output"];
  weightReached: Scalars["Float"]["output"];
};

export type OrderItemWeighingsArgs = {
  withCancelled?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** The connection type for OrderItem. */
export type OrderItemConnection = {
  __typename?: "OrderItemConnection";
  /** A list of edges. */
  edges: Array<OrderItemEdge>;
  /** A list of nodes. */
  nodes: Array<OrderItem>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type OrderItemEdge = {
  __typename?: "OrderItemEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node?: Maybe<OrderItem>;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: "PageInfo";
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars["String"]["output"]>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars["Boolean"]["output"];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars["Boolean"]["output"];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars["String"]["output"]>;
};

export type Plant = {
  __typename?: "Plant";
  id: Scalars["ID"]["output"];
  latestMeasurement: LatestMeasurement;
  name: Scalars["String"]["output"];
  scales: Array<Scale>;
};

export type Query = {
  __typename?: "Query";
  belt?: Maybe<Belt>;
  blanketOrder?: Maybe<BlanketOrder>;
  blanketOrders?: Maybe<BlanketOrderConnection>;
  drivers?: Maybe<DriverConnection>;
  loadAndGoOrders: LoadAndGoOrderConnection;
  location?: Maybe<Location>;
  locations: Array<Location>;
  order?: Maybe<Order>;
  orderItem?: Maybe<OrderItem>;
  orderItems: OrderItemConnection;
  orders: OrderConnection;
  plant?: Maybe<Plant>;
  scale?: Maybe<Scale>;
  scalePositions?: Maybe<Array<ScalePosition>>;
  scales: Array<Scale>;
  user?: Maybe<User>;
  vehicle?: Maybe<Vehicle>;
  vehicles?: Maybe<VehicleConnection>;
};

export type QueryBeltArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryBlanketOrderArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryBlanketOrdersArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryDriversArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryLoadAndGoOrdersArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  queryString?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryLocationArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryOrderArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryOrderItemArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryOrderItemsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryOrdersArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  queryString?: InputMaybe<Scalars["String"]["input"]>;
  state?: InputMaybe<Array<Scalars["String"]["input"]>>;
  vehicleId?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryPlantArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryScaleArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryScalePositionsArgs = {
  from: Scalars["ISO8601DateTime"]["input"];
  id: Scalars["ID"]["input"];
  to: Scalars["ISO8601DateTime"]["input"];
};

export type QueryVehicleArgs = {
  remoteId: Scalars["String"]["input"];
};

export type QueryVehiclesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Scale = {
  __typename?: "Scale";
  belts: Array<Belt>;
  dataPoints: Array<ScaleDataPoint>;
  engineHours?: Maybe<Scalars["Float"]["output"]>;
  hasEngineWorkloadData: Scalars["Boolean"]["output"];
  hasFuelLevelData: Scalars["Boolean"]["output"];
  id: Scalars["ID"]["output"];
  lastWeighingTime?: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  latestMeasurement: LatestMeasurement;
  locations?: Maybe<Array<Location>>;
  mapPosition?: Maybe<ScalePosition>;
  name: Scalars["String"]["output"];
  positionHistoryShowable?: Maybe<Scalars["Boolean"]["output"]>;
  todaysLastWeighing?: Maybe<Weighing>;
  todaysWeighingsTotal?: Maybe<Scalars["String"]["output"]>;
  uid: Scalars["ID"]["output"];
};

export type ScaleDataPointsArgs = {
  from: Scalars["ISO8601DateTime"]["input"];
  to: Scalars["ISO8601DateTime"]["input"];
};

export type ScaleDataPoint = {
  __typename?: "ScaleDataPoint";
  engineWorkloadPercent?: Maybe<Scalars["Float"]["output"]>;
  fuelLevelPercent?: Maybe<Scalars["Float"]["output"]>;
  time: Scalars["ISO8601DateTime"]["output"];
};

export type ScalePosition = {
  __typename?: "ScalePosition";
  latitude: Scalars["Float"]["output"];
  longitude: Scalars["Float"]["output"];
  time?: Maybe<Scalars["ISO8601DateTime"]["output"]>;
};

/** Autogenerated return type of SetZero. */
export type SetZeroPayload = {
  __typename?: "SetZeroPayload";
  errors: Array<Scalars["String"]["output"]>;
  success: Scalars["Boolean"]["output"];
};

/** Autogenerated return type of StartSendingLiveValues. */
export type StartSendingLiveValuesPayload = {
  __typename?: "StartSendingLiveValuesPayload";
  errors: Array<Scalars["String"]["output"]>;
  liveValueReading?: Maybe<VehicleScaleLiveValueReading>;
  success: Scalars["Boolean"]["output"];
};

export type Subscription = {
  __typename?: "Subscription";
  belt: Belt;
  beltDataPoints: Array<BeltDataPoint>;
  location: Location;
  orderItem: OrderItem;
  plant: Plant;
  scale: Scale;
  scaleDataPoints: Array<ScaleDataPoint>;
  scalePositions: Array<ScalePosition>;
  scales: Scale;
  vehicleScaleLiveValues: VehicleScaleLiveValueReading;
};

export type SubscriptionBeltArgs = {
  id: Scalars["ID"]["input"];
};

export type SubscriptionBeltDataPointsArgs = {
  beltId: Scalars["ID"]["input"];
};

export type SubscriptionLocationArgs = {
  id: Scalars["ID"]["input"];
};

export type SubscriptionOrderItemArgs = {
  id: Scalars["ID"]["input"];
};

export type SubscriptionPlantArgs = {
  id: Scalars["ID"]["input"];
};

export type SubscriptionScaleArgs = {
  id: Scalars["ID"]["input"];
};

export type SubscriptionScaleDataPointsArgs = {
  scaleId: Scalars["ID"]["input"];
};

export type SubscriptionScalePositionsArgs = {
  id: Scalars["ID"]["input"];
};

export type SubscriptionVehicleScaleLiveValuesArgs = {
  scaleId: Scalars["ID"]["input"];
};

export type User = {
  __typename?: "User";
  firstname?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  lastname?: Maybe<Scalars["String"]["output"]>;
  loadAndGoQrCode?: Maybe<Scalars["Boolean"]["output"]>;
};

export type Vehicle = {
  __typename?: "Vehicle";
  capacityInKg?: Maybe<Scalars["Float"]["output"]>;
  id: Scalars["ID"]["output"];
  licensePlate: Scalars["String"]["output"];
  remoteId: Scalars["String"]["output"];
  tareDateTime?: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  tareValidTill?: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  tareWeightInKg?: Maybe<Scalars["Float"]["output"]>;
};

/** The connection type for Vehicle. */
export type VehicleConnection = {
  __typename?: "VehicleConnection";
  /** A list of edges. */
  edges: Array<VehicleEdge>;
  /** A list of nodes. */
  nodes: Array<Vehicle>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type VehicleEdge = {
  __typename?: "VehicleEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node?: Maybe<Vehicle>;
};

export type VehicleScaleLiveValueReading = {
  __typename?: "VehicleScaleLiveValueReading";
  scaleNo: Scalars["Int"]["output"];
  standstill: Scalars["Boolean"]["output"];
  systecScaleTimestamp?: Maybe<Scalars["Int"]["output"]>;
  unit: Scalars["String"]["output"];
  weight: Scalars["Float"]["output"];
};

export type Weighing = {
  __typename?: "Weighing";
  bonNumber: Scalars["String"]["output"];
  customer?: Maybe<Customer>;
  date?: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  deliveryPlace?: Maybe<DeliveryPlace>;
  driver?: Maybe<Driver>;
  formattedWeight: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  material?: Maybe<Material>;
  photos: Array<Maybe<WeighingPhoto>>;
  remoteId?: Maybe<Scalars["ID"]["output"]>;
  signatures: Array<Maybe<WeighingSignature>>;
  unit: WeightUnit;
  vehicle?: Maybe<Vehicle>;
  weight: Scalars["Float"]["output"];
};

export type WeighingPhoto = {
  __typename?: "WeighingPhoto";
  description?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  imageUrl: Scalars["String"]["output"];
};

export type WeighingSignature = {
  __typename?: "WeighingSignature";
  description?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  imageUrl: Scalars["String"]["output"];
  role: Scalars["String"]["output"];
};

export enum WeightUnit {
  Dwt = "dwt",
  G = "g",
  Kg = "kg",
  Lb = "lb",
  Lt = "lt",
  Oz = "oz",
  Pd = "pd",
  S = "s",
  St = "st",
  T = "t",
}

export type BlanketOrderFragment = {
  __typename?: "BlanketOrder";
  id: string;
  name: string;
  number: string;
  customer: { __typename?: "Customer"; id: string; name: string };
  blanketOrderItems: Array<{
    __typename?: "BlanketOrderItem";
    id: string;
    unit: WeightUnit;
    remainingWeight?: number | null;
    material: { __typename?: "Material"; id: string; name: string };
  }>;
};

export type CreateOrderFromBlanketOrderMutationVariables = Exact<{
  blanketOrderId: Scalars["ID"]["input"];
  materialId: Scalars["ID"]["input"];
  targetWeight: Scalars["Float"]["input"];
  unit: Scalars["String"]["input"];
  vehicleId?: InputMaybe<Scalars["ID"]["input"]>;
  driverId?: InputMaybe<Scalars["ID"]["input"]>;
}>;

export type CreateOrderFromBlanketOrderMutation = {
  __typename?: "Mutation";
  createOrderFromBlanketOrder: {
    __typename?: "CreateOrderFromBlanketOrderPayload";
    errors: Array<string>;
    order?: {
      __typename?: "Order";
      id: string;
      orderItems: Array<{ __typename?: "OrderItem"; id: string }>;
    } | null;
  };
};

export type DriverFragment = {
  __typename?: "Driver";
  id: string;
  name: string;
};

export type DriverAcceptsDeliveryRequestMutationVariables = Exact<{
  orderId: Scalars["ID"]["input"];
  vehicleId?: InputMaybe<Scalars["ID"]["input"]>;
  driverId?: InputMaybe<Scalars["ID"]["input"]>;
  withWeighings?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type DriverAcceptsDeliveryRequestMutation = {
  __typename?: "Mutation";
  driverAcceptsDeliveryRequest: {
    __typename?: "DriverAcceptsDeliveryRequestPayload";
    errors: Array<string>;
    order?: {
      __typename?: "Order";
      id: string;
      number: string;
      state: string;
      deliveryState: string;
      customer?: { __typename?: "Customer"; id: string; name: string } | null;
      vehicle?: {
        __typename?: "Vehicle";
        id: string;
        capacityInKg?: number | null;
      } | null;
      orderItems: Array<{
        __typename?: "OrderItem";
        id: string;
        unit: WeightUnit;
        targetWeight: number;
        weightReached: number;
        weightPromised: number;
        material: { __typename?: "Material"; name: string };
        weighings?: Array<{
          __typename?: "Weighing";
          bonNumber: string;
          date?: any | null;
          weight: number;
          unit: WeightUnit;
          id: string;
          remoteId?: string | null;
          signatures: Array<{
            __typename?: "WeighingSignature";
            id: string;
            role: string;
            imageUrl: string;
            description?: string | null;
          } | null>;
          driver?: { __typename?: "Driver"; name: string } | null;
          vehicle?: {
            __typename?: "Vehicle";
            id: string;
            licensePlate: string;
          } | null;
          customer?: { __typename?: "Customer"; name: string } | null;
        }>;
      }>;
      deliveryPlace?: {
        __typename?: "DeliveryPlace";
        address?: {
          __typename?: "Address";
          street: string;
          houseNumber?: string | null;
          city: string;
          zipCode: string;
        } | null;
      } | null;
    } | null;
  };
};

export type DriverArrivesOnSiteMutationVariables = Exact<{
  orderId: Scalars["ID"]["input"];
  withWeighings?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type DriverArrivesOnSiteMutation = {
  __typename?: "Mutation";
  driverArrivesOnSite: {
    __typename?: "DriverArrivesOnSitePayload";
    errors: Array<string>;
    order?: {
      __typename?: "Order";
      id: string;
      number: string;
      state: string;
      deliveryState: string;
      customer?: { __typename?: "Customer"; id: string; name: string } | null;
      vehicle?: {
        __typename?: "Vehicle";
        id: string;
        capacityInKg?: number | null;
      } | null;
      orderItems: Array<{
        __typename?: "OrderItem";
        id: string;
        unit: WeightUnit;
        targetWeight: number;
        weightReached: number;
        weightPromised: number;
        material: { __typename?: "Material"; name: string };
        weighings?: Array<{
          __typename?: "Weighing";
          bonNumber: string;
          date?: any | null;
          weight: number;
          unit: WeightUnit;
          id: string;
          remoteId?: string | null;
          signatures: Array<{
            __typename?: "WeighingSignature";
            id: string;
            role: string;
            imageUrl: string;
            description?: string | null;
          } | null>;
          driver?: { __typename?: "Driver"; name: string } | null;
          vehicle?: {
            __typename?: "Vehicle";
            id: string;
            licensePlate: string;
          } | null;
          customer?: { __typename?: "Customer"; name: string } | null;
        }>;
      }>;
      deliveryPlace?: {
        __typename?: "DeliveryPlace";
        address?: {
          __typename?: "Address";
          street: string;
          houseNumber?: string | null;
          city: string;
          zipCode: string;
        } | null;
      } | null;
    } | null;
  };
};

export type GetBlanketOrderQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type GetBlanketOrderQuery = {
  __typename?: "Query";
  blanketOrder?: {
    __typename?: "BlanketOrder";
    id: string;
    name: string;
    number: string;
    customer: { __typename?: "Customer"; id: string; name: string };
    blanketOrderItems: Array<{
      __typename?: "BlanketOrderItem";
      id: string;
      unit: WeightUnit;
      remainingWeight?: number | null;
      material: { __typename?: "Material"; id: string; name: string };
    }>;
  } | null;
};

export type GetBlanketOrdersQueryVariables = Exact<{ [key: string]: never }>;

export type GetBlanketOrdersQuery = {
  __typename?: "Query";
  blanketOrders?: {
    __typename?: "BlanketOrderConnection";
    nodes: Array<{
      __typename?: "BlanketOrder";
      id: string;
      name: string;
      number: string;
      customer: { __typename?: "Customer"; id: string; name: string };
      blanketOrderItems: Array<{
        __typename?: "BlanketOrderItem";
        id: string;
        unit: WeightUnit;
        remainingWeight?: number | null;
        material: { __typename?: "Material"; id: string; name: string };
      }>;
    }>;
  } | null;
};

export type GetDriversQueryVariables = Exact<{ [key: string]: never }>;

export type GetDriversQuery = {
  __typename?: "Query";
  drivers?: {
    __typename?: "DriverConnection";
    nodes: Array<{ __typename?: "Driver"; id: string; name: string }>;
  } | null;
};

export type GetOrderQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
  withWeighings?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type GetOrderQuery = {
  __typename?: "Query";
  order?: {
    __typename?: "Order";
    id: string;
    number: string;
    state: string;
    deliveryState: string;
    customer?: { __typename?: "Customer"; id: string; name: string } | null;
    vehicle?: {
      __typename?: "Vehicle";
      id: string;
      capacityInKg?: number | null;
    } | null;
    orderItems: Array<{
      __typename?: "OrderItem";
      id: string;
      unit: WeightUnit;
      targetWeight: number;
      weightReached: number;
      weightPromised: number;
      material: { __typename?: "Material"; name: string };
      weighings?: Array<{
        __typename?: "Weighing";
        bonNumber: string;
        date?: any | null;
        weight: number;
        unit: WeightUnit;
        id: string;
        remoteId?: string | null;
        signatures: Array<{
          __typename?: "WeighingSignature";
          id: string;
          role: string;
          imageUrl: string;
          description?: string | null;
        } | null>;
        driver?: { __typename?: "Driver"; name: string } | null;
        vehicle?: {
          __typename?: "Vehicle";
          id: string;
          licensePlate: string;
        } | null;
        customer?: { __typename?: "Customer"; name: string } | null;
      }>;
    }>;
    deliveryPlace?: {
      __typename?: "DeliveryPlace";
      address?: {
        __typename?: "Address";
        street: string;
        houseNumber?: string | null;
        city: string;
        zipCode: string;
      } | null;
    } | null;
  } | null;
};

export type GetOrdersQueryVariables = Exact<{
  state?: InputMaybe<
    Array<Scalars["String"]["input"]> | Scalars["String"]["input"]
  >;
  vehicleId?: InputMaybe<Scalars["String"]["input"]>;
  queryString?: InputMaybe<Scalars["String"]["input"]>;
  withWeighings?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type GetOrdersQuery = {
  __typename?: "Query";
  orders: {
    __typename?: "OrderConnection";
    nodes: Array<{
      __typename?: "Order";
      id: string;
      number: string;
      state: string;
      deliveryState: string;
      customer?: { __typename?: "Customer"; id: string; name: string } | null;
      vehicle?: {
        __typename?: "Vehicle";
        id: string;
        capacityInKg?: number | null;
      } | null;
      orderItems: Array<{
        __typename?: "OrderItem";
        id: string;
        unit: WeightUnit;
        targetWeight: number;
        weightReached: number;
        weightPromised: number;
        material: { __typename?: "Material"; name: string };
        weighings?: Array<{
          __typename?: "Weighing";
          bonNumber: string;
          date?: any | null;
          weight: number;
          unit: WeightUnit;
          id: string;
          remoteId?: string | null;
          signatures: Array<{
            __typename?: "WeighingSignature";
            id: string;
            role: string;
            imageUrl: string;
            description?: string | null;
          } | null>;
          driver?: { __typename?: "Driver"; name: string } | null;
          vehicle?: {
            __typename?: "Vehicle";
            id: string;
            licensePlate: string;
          } | null;
          customer?: { __typename?: "Customer"; name: string } | null;
        }>;
      }>;
      deliveryPlace?: {
        __typename?: "DeliveryPlace";
        address?: {
          __typename?: "Address";
          street: string;
          houseNumber?: string | null;
          city: string;
          zipCode: string;
        } | null;
      } | null;
    }>;
  };
};

export type GetUserQueryVariables = Exact<{ [key: string]: never }>;

export type GetUserQuery = {
  __typename?: "Query";
  user?: {
    __typename?: "User";
    id: string;
    firstname?: string | null;
    lastname?: string | null;
    loadAndGoQrCode?: boolean | null;
  } | null;
};

export type GetVehiclesQueryVariables = Exact<{ [key: string]: never }>;

export type GetVehiclesQuery = {
  __typename?: "Query";
  vehicles?: {
    __typename?: "VehicleConnection";
    nodes: Array<{ __typename?: "Vehicle"; id: string; licensePlate: string }>;
  } | null;
};

export type LoadAndGoOrderFragment = {
  __typename?: "LoadAndGoOrder";
  orderType: string;
  order?: {
    __typename?: "Order";
    id: string;
    number: string;
    state: string;
    deliveryState: string;
    customer?: { __typename?: "Customer"; id: string; name: string } | null;
    vehicle?: {
      __typename?: "Vehicle";
      id: string;
      capacityInKg?: number | null;
    } | null;
    orderItems: Array<{
      __typename?: "OrderItem";
      id: string;
      unit: WeightUnit;
      targetWeight: number;
      weightReached: number;
      weightPromised: number;
      material: { __typename?: "Material"; name: string };
      weighings?: Array<{
        __typename?: "Weighing";
        bonNumber: string;
        date?: any | null;
        weight: number;
        unit: WeightUnit;
        id: string;
        remoteId?: string | null;
        signatures: Array<{
          __typename?: "WeighingSignature";
          id: string;
          role: string;
          imageUrl: string;
          description?: string | null;
        } | null>;
        driver?: { __typename?: "Driver"; name: string } | null;
        vehicle?: {
          __typename?: "Vehicle";
          id: string;
          licensePlate: string;
        } | null;
        customer?: { __typename?: "Customer"; name: string } | null;
      }>;
    }>;
    deliveryPlace?: {
      __typename?: "DeliveryPlace";
      address?: {
        __typename?: "Address";
        street: string;
        houseNumber?: string | null;
        city: string;
        zipCode: string;
      } | null;
    } | null;
  } | null;
  blanketOrder?: {
    __typename?: "BlanketOrder";
    id: string;
    name: string;
    number: string;
    customer: { __typename?: "Customer"; id: string; name: string };
    blanketOrderItems: Array<{
      __typename?: "BlanketOrderItem";
      id: string;
      unit: WeightUnit;
      remainingWeight?: number | null;
      material: { __typename?: "Material"; id: string; name: string };
    }>;
  } | null;
};

export type OrderFragment = {
  __typename?: "Order";
  id: string;
  number: string;
  state: string;
  deliveryState: string;
  customer?: { __typename?: "Customer"; id: string; name: string } | null;
  vehicle?: {
    __typename?: "Vehicle";
    id: string;
    capacityInKg?: number | null;
  } | null;
  orderItems: Array<{
    __typename?: "OrderItem";
    id: string;
    unit: WeightUnit;
    targetWeight: number;
    weightReached: number;
    weightPromised: number;
    material: { __typename?: "Material"; name: string };
    weighings?: Array<{
      __typename?: "Weighing";
      bonNumber: string;
      date?: any | null;
      weight: number;
      unit: WeightUnit;
      id: string;
      remoteId?: string | null;
      signatures: Array<{
        __typename?: "WeighingSignature";
        id: string;
        role: string;
        imageUrl: string;
        description?: string | null;
      } | null>;
      driver?: { __typename?: "Driver"; name: string } | null;
      vehicle?: {
        __typename?: "Vehicle";
        id: string;
        licensePlate: string;
      } | null;
      customer?: { __typename?: "Customer"; name: string } | null;
    }>;
  }>;
  deliveryPlace?: {
    __typename?: "DeliveryPlace";
    address?: {
      __typename?: "Address";
      street: string;
      houseNumber?: string | null;
      city: string;
      zipCode: string;
    } | null;
  } | null;
};

export type OrderItemFragment = {
  __typename?: "OrderItem";
  id: string;
  unit: WeightUnit;
  targetWeight: number;
  weightReached: number;
  weightPromised: number;
  material: { __typename?: "Material"; name: string };
  weighings?: Array<{
    __typename?: "Weighing";
    bonNumber: string;
    date?: any | null;
    weight: number;
    unit: WeightUnit;
    id: string;
    remoteId?: string | null;
    signatures: Array<{
      __typename?: "WeighingSignature";
      id: string;
      role: string;
      imageUrl: string;
      description?: string | null;
    } | null>;
    driver?: { __typename?: "Driver"; name: string } | null;
    vehicle?: {
      __typename?: "Vehicle";
      id: string;
      licensePlate: string;
    } | null;
    customer?: { __typename?: "Customer"; name: string } | null;
  }>;
};

export type OrderItemSubscriptionVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type OrderItemSubscription = {
  __typename?: "Subscription";
  orderItem: {
    __typename?: "OrderItem";
    weightPromised: number;
    weightReached: number;
    weighings: Array<{ __typename?: "Weighing"; id: string }>;
  };
};

export type VehicleFragment = {
  __typename?: "Vehicle";
  id: string;
  licensePlate: string;
};

export type DriverFreesOrderMutationVariables = Exact<{
  orderId: Scalars["ID"]["input"];
  withWeighings?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type DriverFreesOrderMutation = {
  __typename?: "Mutation";
  driverFreesOrder: {
    __typename?: "DriverFreesOrderPayload";
    errors: Array<string>;
    order?: {
      __typename?: "Order";
      id: string;
      number: string;
      state: string;
      deliveryState: string;
      customer?: { __typename?: "Customer"; id: string; name: string } | null;
      vehicle?: {
        __typename?: "Vehicle";
        id: string;
        capacityInKg?: number | null;
      } | null;
      orderItems: Array<{
        __typename?: "OrderItem";
        id: string;
        unit: WeightUnit;
        targetWeight: number;
        weightReached: number;
        weightPromised: number;
        material: { __typename?: "Material"; name: string };
        weighings?: Array<{
          __typename?: "Weighing";
          bonNumber: string;
          date?: any | null;
          weight: number;
          unit: WeightUnit;
          id: string;
          remoteId?: string | null;
          signatures: Array<{
            __typename?: "WeighingSignature";
            id: string;
            role: string;
            imageUrl: string;
            description?: string | null;
          } | null>;
          driver?: { __typename?: "Driver"; name: string } | null;
          vehicle?: {
            __typename?: "Vehicle";
            id: string;
            licensePlate: string;
          } | null;
          customer?: { __typename?: "Customer"; name: string } | null;
        }>;
      }>;
      deliveryPlace?: {
        __typename?: "DeliveryPlace";
        address?: {
          __typename?: "Address";
          street: string;
          houseNumber?: string | null;
          city: string;
          zipCode: string;
        } | null;
      } | null;
    } | null;
  };
};

export type GetCombinedOrdersQueryVariables = Exact<{
  queryString?: InputMaybe<Scalars["String"]["input"]>;
  withWeighings?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type GetCombinedOrdersQuery = {
  __typename?: "Query";
  loadAndGoOrders: {
    __typename?: "LoadAndGoOrderConnection";
    nodes: Array<{
      __typename?: "LoadAndGoOrder";
      orderType: string;
      order?: {
        __typename?: "Order";
        id: string;
        number: string;
        state: string;
        deliveryState: string;
        customer?: { __typename?: "Customer"; id: string; name: string } | null;
        vehicle?: {
          __typename?: "Vehicle";
          id: string;
          capacityInKg?: number | null;
        } | null;
        orderItems: Array<{
          __typename?: "OrderItem";
          id: string;
          unit: WeightUnit;
          targetWeight: number;
          weightReached: number;
          weightPromised: number;
          material: { __typename?: "Material"; name: string };
          weighings?: Array<{
            __typename?: "Weighing";
            bonNumber: string;
            date?: any | null;
            weight: number;
            unit: WeightUnit;
            id: string;
            remoteId?: string | null;
            signatures: Array<{
              __typename?: "WeighingSignature";
              id: string;
              role: string;
              imageUrl: string;
              description?: string | null;
            } | null>;
            driver?: { __typename?: "Driver"; name: string } | null;
            vehicle?: {
              __typename?: "Vehicle";
              id: string;
              licensePlate: string;
            } | null;
            customer?: { __typename?: "Customer"; name: string } | null;
          }>;
        }>;
        deliveryPlace?: {
          __typename?: "DeliveryPlace";
          address?: {
            __typename?: "Address";
            street: string;
            houseNumber?: string | null;
            city: string;
            zipCode: string;
          } | null;
        } | null;
      } | null;
      blanketOrder?: {
        __typename?: "BlanketOrder";
        id: string;
        name: string;
        number: string;
        customer: { __typename?: "Customer"; id: string; name: string };
        blanketOrderItems: Array<{
          __typename?: "BlanketOrderItem";
          id: string;
          unit: WeightUnit;
          remainingWeight?: number | null;
          material: { __typename?: "Material"; id: string; name: string };
        }>;
      } | null;
    }>;
  };
};

export const DriverFragmentDoc = gql`
  fragment Driver on Driver {
    id
    name
  }
`;
export const OrderItemFragmentDoc = gql`
  fragment OrderItem on OrderItem {
    id
    unit
    targetWeight
    weightReached
    weightPromised
    material {
      name
    }
    weighings @include(if: $withWeighings) {
      bonNumber
      date
      weight
      unit
      id
      remoteId
      signatures {
        id
        role
        imageUrl
        description
      }
      driver {
        name
      }
      vehicle {
        id
        licensePlate
      }
      customer {
        name
      }
    }
  }
`;
export const OrderFragmentDoc = gql`
  fragment Order on Order {
    id
    number
    state
    deliveryState
    customer {
      id
      name
    }
    vehicle {
      id
      capacityInKg
    }
    orderItems {
      ...OrderItem
    }
    deliveryPlace {
      address {
        street
        houseNumber
        city
        zipCode
      }
    }
  }
  ${OrderItemFragmentDoc}
`;
export const BlanketOrderFragmentDoc = gql`
  fragment BlanketOrder on BlanketOrder {
    id
    name
    number
    customer {
      id
      name
    }
    blanketOrderItems {
      id
      material {
        id
        name
      }
      unit
      remainingWeight
    }
  }
`;
export const LoadAndGoOrderFragmentDoc = gql`
  fragment LoadAndGoOrder on LoadAndGoOrder {
    orderType
    order {
      ...Order
    }
    blanketOrder {
      ...BlanketOrder
    }
  }
  ${OrderFragmentDoc}
  ${BlanketOrderFragmentDoc}
`;
export const VehicleFragmentDoc = gql`
  fragment Vehicle on Vehicle {
    id
    licensePlate
  }
`;
export const CreateOrderFromBlanketOrderDocument = gql`
  mutation createOrderFromBlanketOrder(
    $blanketOrderId: ID!
    $materialId: ID!
    $targetWeight: Float!
    $unit: String!
    $vehicleId: ID
    $driverId: ID
  ) {
    createOrderFromBlanketOrder(
      blanketOrderId: $blanketOrderId
      materialId: $materialId
      targetWeight: $targetWeight
      unit: $unit
      vehicleId: $vehicleId
      driverId: $driverId
    ) {
      errors
      order {
        id
        orderItems {
          id
        }
      }
    }
  }
`;

export function useCreateOrderFromBlanketOrderMutation() {
  return Urql.useMutation<
    CreateOrderFromBlanketOrderMutation,
    CreateOrderFromBlanketOrderMutationVariables
  >(CreateOrderFromBlanketOrderDocument);
}
export const DriverAcceptsDeliveryRequestDocument = gql`
  mutation driverAcceptsDeliveryRequest(
    $orderId: ID!
    $vehicleId: ID
    $driverId: ID
    $withWeighings: Boolean = false
  ) {
    driverAcceptsDeliveryRequest(
      orderId: $orderId
      vehicleId: $vehicleId
      driverId: $driverId
    ) {
      order {
        ...Order
      }
      errors
    }
  }
  ${OrderFragmentDoc}
`;

export function useDriverAcceptsDeliveryRequestMutation() {
  return Urql.useMutation<
    DriverAcceptsDeliveryRequestMutation,
    DriverAcceptsDeliveryRequestMutationVariables
  >(DriverAcceptsDeliveryRequestDocument);
}
export const DriverArrivesOnSiteDocument = gql`
  mutation driverArrivesOnSite($orderId: ID!, $withWeighings: Boolean = false) {
    driverArrivesOnSite(orderId: $orderId) {
      order {
        ...Order
      }
      errors
    }
  }
  ${OrderFragmentDoc}
`;

export function useDriverArrivesOnSiteMutation() {
  return Urql.useMutation<
    DriverArrivesOnSiteMutation,
    DriverArrivesOnSiteMutationVariables
  >(DriverArrivesOnSiteDocument);
}
export const GetBlanketOrderDocument = gql`
  query GetBlanketOrder($id: ID!) {
    blanketOrder(id: $id) {
      ...BlanketOrder
    }
  }
  ${BlanketOrderFragmentDoc}
`;

export function useGetBlanketOrderQuery(
  options: Omit<
    Urql.UseQueryArgs<never, GetBlanketOrderQueryVariables>,
    "query"
  >,
) {
  return Urql.useQuery<GetBlanketOrderQuery, GetBlanketOrderQueryVariables>({
    query: GetBlanketOrderDocument,
    ...options,
  });
}
export const GetBlanketOrdersDocument = gql`
  query getBlanketOrders {
    blanketOrders {
      nodes {
        ...BlanketOrder
      }
    }
  }
  ${BlanketOrderFragmentDoc}
`;

export function useGetBlanketOrdersQuery(
  options: Omit<
    Urql.UseQueryArgs<never, GetBlanketOrdersQueryVariables>,
    "query"
  >,
) {
  return Urql.useQuery<GetBlanketOrdersQuery, GetBlanketOrdersQueryVariables>({
    query: GetBlanketOrdersDocument,
    ...options,
  });
}
export const GetDriversDocument = gql`
  query getDrivers {
    drivers {
      nodes {
        ...Driver
      }
    }
  }
  ${DriverFragmentDoc}
`;

export function useGetDriversQuery(
  options: Omit<Urql.UseQueryArgs<never, GetDriversQueryVariables>, "query">,
) {
  return Urql.useQuery<GetDriversQuery, GetDriversQueryVariables>({
    query: GetDriversDocument,
    ...options,
  });
}
export const GetOrderDocument = gql`
  query GetOrder($id: ID!, $withWeighings: Boolean = false) {
    order(id: $id) {
      ...Order
    }
  }
  ${OrderFragmentDoc}
`;

export function useGetOrderQuery(
  options: Omit<Urql.UseQueryArgs<never, GetOrderQueryVariables>, "query">,
) {
  return Urql.useQuery<GetOrderQuery, GetOrderQueryVariables>({
    query: GetOrderDocument,
    ...options,
  });
}
export const GetOrdersDocument = gql`
  query getOrders(
    $state: [String!]
    $vehicleId: String
    $queryString: String
    $withWeighings: Boolean = false
  ) {
    orders(state: $state, vehicleId: $vehicleId, queryString: $queryString) {
      nodes {
        ...Order
      }
    }
  }
  ${OrderFragmentDoc}
`;

export function useGetOrdersQuery(
  options: Omit<Urql.UseQueryArgs<never, GetOrdersQueryVariables>, "query">,
) {
  return Urql.useQuery<GetOrdersQuery, GetOrdersQueryVariables>({
    query: GetOrdersDocument,
    ...options,
  });
}
export const GetUserDocument = gql`
  query GetUser {
    user {
      id
      firstname
      lastname
      loadAndGoQrCode
    }
  }
`;

export function useGetUserQuery(
  options: Omit<Urql.UseQueryArgs<never, GetUserQueryVariables>, "query">,
) {
  return Urql.useQuery<GetUserQuery, GetUserQueryVariables>({
    query: GetUserDocument,
    ...options,
  });
}
export const GetVehiclesDocument = gql`
  query getVehicles {
    vehicles {
      nodes {
        ...Vehicle
      }
    }
  }
  ${VehicleFragmentDoc}
`;

export function useGetVehiclesQuery(
  options: Omit<Urql.UseQueryArgs<never, GetVehiclesQueryVariables>, "query">,
) {
  return Urql.useQuery<GetVehiclesQuery, GetVehiclesQueryVariables>({
    query: GetVehiclesDocument,
    ...options,
  });
}
export const OrderItemDocument = gql`
  subscription OrderItem($id: ID!) {
    orderItem(id: $id) {
      weightPromised
      weightReached
      weighings {
        id
      }
    }
  }
`;

export function useOrderItemSubscription<R = OrderItemSubscription>(
  options: Omit<
    Urql.UseSubscriptionArgs<never, OrderItemSubscriptionVariables>,
    "query"
  >,
  handler?: Urql.SubscriptionHandlerArg<OrderItemSubscription, R>,
) {
  return Urql.useSubscription<
    OrderItemSubscription,
    R,
    OrderItemSubscriptionVariables
  >({ query: OrderItemDocument, ...options }, handler);
}
export const DriverFreesOrderDocument = gql`
  mutation driverFreesOrder($orderId: ID!, $withWeighings: Boolean = false) {
    driverFreesOrder(orderId: $orderId) {
      order {
        ...Order
      }
      errors
    }
  }
  ${OrderFragmentDoc}
`;

export function useDriverFreesOrderMutation() {
  return Urql.useMutation<
    DriverFreesOrderMutation,
    DriverFreesOrderMutationVariables
  >(DriverFreesOrderDocument);
}
export const GetCombinedOrdersDocument = gql`
  query getCombinedOrders(
    $queryString: String = ""
    $withWeighings: Boolean = false
  ) {
    loadAndGoOrders(queryString: $queryString) {
      nodes {
        ...LoadAndGoOrder
      }
    }
  }
  ${LoadAndGoOrderFragmentDoc}
`;

export function useGetCombinedOrdersQuery(
  options: Omit<
    Urql.UseQueryArgs<never, GetCombinedOrdersQueryVariables>,
    "query"
  >,
) {
  return Urql.useQuery<GetCombinedOrdersQuery, GetCombinedOrdersQueryVariables>(
    { query: GetCombinedOrdersDocument, ...options },
  );
}
