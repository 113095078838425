<script setup lang="ts">
import { ref, type PropType } from "vue";

const props = defineProps({
  id: {
    type: String as PropType<string>,
    required: true,
  },
  modelValue: {
    type: String as PropType<string | null>,
    required: true,
  },
  fieldName: {
    type: String as PropType<string>,
    required: true,
  },
  label: {
    type: String as PropType<string>,
    required: true,
  },
  required: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  options: {
    type: Array as PropType<{ label: string; value?: string | undefined }[]>,
    required: true,
  },
});

const emit = defineEmits<(e: "update:modelValue", value: string) => void>();

const selectedOption = ref(props.modelValue);

const selectOption = (event: Event) => {
  const target = event.target as HTMLSelectElement;
  selectedOption.value = target.value;
  emit("update:modelValue", selectedOption.value);
};
</script>

<template>
  <div class="form--group">
    <pfreundt-select
      :id="id"
      :field-name="fieldName"
      size="m"
      :label="label"
      :required="required"
      :toggle-select-label="$t('forms.select.toggle')"
      color="secondary"
      @change="selectOption"
    >
      <pfreundt-option v-if="!selectedOption" value>
        {{ $t("forms.select.default") }}
      </pfreundt-option>

      <pfreundt-option
        v-for="(option, index) in options"
        :key="index"
        :value="option.value"
        :selected="option.value === selectedOption"
      >
        {{ option.label }}
      </pfreundt-option>
    </pfreundt-select>
  </div>
</template>
