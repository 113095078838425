<script setup lang="ts">
import { PageLayout } from "@/layouts";
import {
  IconLink,
  CancelOrderDialog,
  LoadingStateDisplay,
  OrderCard,
  PfrButton,
  SkeletonOrderCard,
  TitleHeader,
  LoadingSpinner,
} from "@/components";
import { ButtonElement, LoadingState, RouteName } from "@/types";
import { useI18n } from "vue-i18n";
import { useOrderStore } from "@/stores";
import { storeToRefs } from "pinia";
import { computed, onBeforeMount, ref } from "vue";
import type { PropType } from "vue";
import { useBackLink } from "@/composables";
import qrCode from "qrcode";

const props = defineProps({
  orderId: {
    type: String as PropType<string>,
    required: true,
  },
  itemId: {
    type: String as PropType<string>,
    required: true,
  },
});

const { t } = useI18n();
const orderStore = useOrderStore();
const { order, fetching } = storeToRefs(orderStore);
const { fetchOrder } = orderStore;

const { overviewLink: getOverviewLink } = useBackLink();

const qrCodeSize: number = 500;

const qrCodeUrl = ref<string>();

const loadQrCode = () => {
  qrCode
    .toDataURL(order.value!.orderNumber, { width: qrCodeSize })
    .then((url: string) => {
      qrCodeUrl.value = url;
    });
};

const overviewLink = computed(() =>
  getOverviewLink({
    orderState: order.value?.state,
    userVehicleId: order.value?.vehicle?.id,
    orderVehicleId: order.value?.vehicle?.id,
  }),
);

onBeforeMount(async () => {
  await fetchOrder(props.orderId, props.itemId);
  loadQrCode();
});
</script>

<template>
  <PageLayout>
    <template #header>
      <TitleHeader :heading="t('views.scanQrCode.heading')">
        <template #left>
          <IconLink :to="overviewLink" />
        </template>
        <template #right>
          <CancelOrderDialog
            v-if="order?.orderNumber"
            :order-number="order.orderNumber"
            :order-id="orderId"
          />
        </template>
      </TitleHeader>
    </template>

    <template #main>
      <pfreundt-heading balanced centered tag="h2" size="xsmall" weight="bold">
        {{ t("views.scanQrCode.description") }}
      </pfreundt-heading>

      <div class="qr-code">
        <img v-if="qrCodeUrl" class="qr-code--image" :src="qrCodeUrl" />
        <LoadingSpinner v-else />
      </div>

      <LoadingStateDisplay
        :loading-state="fetching ? LoadingState.Loading : LoadingState.Success"
      >
        <template #loading>
          <SkeletonOrderCard />
        </template>

        <template #default>
          <OrderCard v-if="order" :entry="order" :without-link="true" />

          <div v-else>{{ t("views.errors.failedToFetchOrder") }}</div>
        </template>
      </LoadingStateDisplay>
    </template>

    <template #mainFooter>
      <PfrButton
        :is="ButtonElement.RouterLink"
        full-width
        :disabled="fetching"
        :to="{
          name: RouteName.Loading,
          params: { orderId, itemId },
        }"
      >
        {{ t("views.scanQrCode.confirm") }}
      </PfrButton>
    </template>
  </PageLayout>
</template>

<style>
.qr-code {
  --qr-code-size: 250px;

  display: flex;
  justify-content: center;
  align-items: center;
  inline-size: var(--qr-code-size);
  block-size: var(--qr-code-size);
  margin-block: var(--spacing-32);
  margin-inline: auto;
}

.qr-code--image {
  inline-size: 100%;
}
</style>
