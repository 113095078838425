<script setup lang="ts">
import { PageLayout } from "@/layouts";
import {
  IconLink,
  CancelOrderDialog,
  LoadingStateDisplay,
  OrderCard,
  PfrButton,
  SkeletonOrderCard,
  TitleHeader,
} from "@/components";
import { ButtonElement, LoadingState, RouteName } from "@/types";
import { useI18n } from "vue-i18n";
import { useOrderStore, useUserStore } from "@/stores";
import { storeToRefs } from "pinia";
import { computed, onBeforeMount } from "vue";
import type { PropType } from "vue";
import { useBackLink, useOrderRedirectToStep } from "@/composables";
import { useRouter } from "vue-router";

const props = defineProps({
  orderId: {
    type: String as PropType<string>,
    required: true,
  },
  itemId: {
    type: String as PropType<string>,
    required: true,
  },
});

const { t } = useI18n();
const orderStore = useOrderStore();
const { order, fetching } = storeToRefs(orderStore);
const { fetchOrder, startLoading } = orderStore;

const { overviewLink } = useBackLink();

const userStore = useUserStore();

const router = useRouter();

const hasQRCodeActivated = computed(
  () => userStore.user?.loadAndGoQrCode ?? false,
);

const onConfirmArrival = async () => {
  await startLoading(props.orderId, props.itemId);

  if (hasQRCodeActivated.value) {
    router.push({
      name: RouteName.ScanQrCode,
      params: {
        orderId: props.orderId,
        itemId: props.itemId,
      },
    });
  }
};

onBeforeMount(() => {
  fetchOrder(props.orderId, props.itemId);
});

useOrderRedirectToStep(order);
</script>

<template>
  <PageLayout>
    <template #header>
      <TitleHeader :heading="t('views.confirmArrival.heading')">
        <template #left>
          <IconLink
            :to="
              overviewLink({
                orderState: order?.state,
                userVehicleId: order?.vehicle?.id,
                orderVehicleId: order?.vehicle?.id,
              })
            "
          />
        </template>
        <template #right>
          <CancelOrderDialog
            v-if="order?.orderNumber"
            :order-number="order.orderNumber"
            :order-id="orderId"
          />
        </template>
      </TitleHeader>
    </template>

    <template #main>
      <pfreundt-heading balanced centered tag="h2" size="xsmall" weight="bold">
        {{ t("views.confirmArrival.description") }}
      </pfreundt-heading>

      <img class="arrival-badge" src="@/assets/images/arrivalBadge.png" />

      <LoadingStateDisplay
        :loading-state="fetching ? LoadingState.Loading : LoadingState.Success"
      >
        <template #loading>
          <SkeletonOrderCard />
        </template>

        <template #default>
          <OrderCard v-if="order" :entry="order" :without-link="true" />

          <div v-else>{{ t("views.errors.failedToFetchOrder") }}</div>
        </template>
      </LoadingStateDisplay>
    </template>

    <template #mainFooter>
      <PfrButton
        :is="ButtonElement.Button"
        full-width
        :disabled="fetching"
        @click="onConfirmArrival()"
      >
        {{ t("views.confirmArrival.confirm") }}
      </PfrButton>
    </template>
  </PageLayout>
</template>

<style>
.arrival-badge {
  max-inline-size: 164px;
  margin-block: var(--spacing-64);
  margin-inline: auto;
}
</style>
